import React, { useCallback } from 'react';
import { I18n } from '@aws-amplify/core';
import * as Icons from '@ant-design/icons';
import { Row, Col, Alert, Form, Button, Divider, FormInstance } from 'antd';

import { FormData } from './types';
import Logo from '@/assets/img/logo_login.png';
import { Container, ImageBox, CustomInput, CustomInputPassword, Link, WrapperCheckbox } from './styles';

interface LoginFormProps {
	form: FormInstance;
	loading?: boolean;
	errors: any;
	onFinish: (values: FormData) => Promise<void>;
}

const WrapperAlert = ({ message = '' }: { message: string }) => (
	<Col span={24} style={{ paddingBottom: '1.5rem' }}>
		<Alert showIcon type="error" style={{ textAlign: 'center' }} message={I18n.get(message)} />
	</Col>
);

export function LoginForm({ form, loading = false, errors, onFinish }: Readonly<LoginFormProps>) {
	const IconsEyePassword = useCallback((visible: boolean) => {
		return visible ? <Icons.EyeTwoTone /> : <Icons.EyeInvisibleOutlined />;
	}, []);

	return (
		<Container xs={24} sm={18} md={14} lg={10} xl={8} xxl={6}>
			<Row>
				<Col span={24}>
					<ImageBox>
						<img alt="logo" src={Logo} />
					</ImageBox>
				</Col>
				<Col span={24}>
					<Form form={form} onFinish={onFinish} initialValues={{ remember: true }}>
						<Row>
							{errors && <WrapperAlert message={errors.response?.data.message || errors?.message} />}
							<Col span={24}>
								<Form.Item
									name="email"
									hasFeedback
									rules={[
										{
											type: 'email',
											message: I18n.get('Enter a valid email')
										},
										{
											required: true,
											message: I18n.get('Enter your email')
										}
									]}
								>
									<CustomInput
										placeholder="E-mail"
										prefix={<Icons.UserOutlined className="site-form-item-icon" />}
									/>
								</Form.Item>
							</Col>
							<Col span={24}>
								<Form.Item
									name="password"
									style={{ marginBottom: '10%' }}
									hasFeedback
									rules={[
										{
											required: true,
											message: I18n.get('Enter your password')
										}
									]}
								>
									<CustomInputPassword
										iconRender={IconsEyePassword}
										placeholder={I18n.get('Password')}
										prefix={<Icons.LockOutlined className="site-form-item-icon" />}
									/>
								</Form.Item>
							</Col>
							<Col span={24}>
								<Row gutter={[0, 26]}>
									<Col xs={12} xl={10} xxl={12}>
										<Form.Item name="remember" valuePropName="checked" noStyle>
											<WrapperCheckbox>{I18n.get('Remember me')}</WrapperCheckbox>
										</Form.Item>
									</Col>
									<Col xs={12} xl={14} xxl={12}>
										<Link align="end" href="/forgot-password">
											{I18n.get('Forgot password')}
										</Link>
									</Col>
									<Col span={24}>
										<Form.Item>
											<Button
												size="large"
												type="primary"
												htmlType="submit"
												loading={loading}
												style={{ width: '100%' }}
											>
												{I18n.get('Login')}
											</Button>
										</Form.Item>
										<Divider />
										<Link align="center" href="/sso/login">
											{I18n.get('Continue with SSO')}
										</Link>
									</Col>
								</Row>
							</Col>
						</Row>
					</Form>
				</Col>
			</Row>
		</Container>
	);
}
