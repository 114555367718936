import React from 'react';
import { Row, Col, Form, Input, Radio, DatePicker, Button, FormInstance } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { I18n } from '@aws-amplify/core';

import { InitialValues } from './types';

interface FormFilterProps {
	form: FormInstance;
	initialValues: InitialValues;
	onClear: () => void;
	onSubmit: () => Promise<void>;
}

export const FormFilter: React.FC<FormFilterProps> = ({ form, initialValues, onSubmit, onClear }) => (
	<Form form={form} initialValues={initialValues} layout="vertical">
		<Row gutter={[12, 0]}>
			<Col xs={24} sm={24} md={12} lg={8} xxl={8}>
				<Form.Item label={I18n.get('Nome')} name="organization">
					<Input placeholder={I18n.get('Company name')} />
				</Form.Item>
			</Col>
			<Col xs={24} sm={24} md={12} lg={8} xxl={8}>
				<Form.Item label={I18n.get('Created At')} name="created_at">
					<DatePicker.RangePicker format="DD-MM-YYYY" style={{ width: '100%' }} />
				</Form.Item>
			</Col>
			<Col xs={24} sm={24} md={24} lg={8} xxl={8}>
				<Form.Item label={I18n.get('Status')} name="status">
					<Radio.Group>
						<Radio.Button value="active">{I18n.get('Active')}</Radio.Button>
						<Radio.Button value="inactive">{I18n.get('Inactive')}</Radio.Button>
						<Radio.Button value="all">{I18n.get('All')}</Radio.Button>
					</Radio.Group>
				</Form.Item>
			</Col>
		</Row>
		<Row gutter={[12, 10]}>
			<Col xs={24} sm={12} md={8} lg={6} xl={4}>
				<Button ghost type="primary" onClick={onSubmit} icon={<SearchOutlined />} style={{ width: '100%' }}>
					{I18n.get('Search')}
				</Button>
			</Col>
			<Col xs={24} sm={12} md={8} lg={6} xl={4}>
				<Button type="ghost" style={{ width: '100%' }} onClick={onClear}>
					{I18n.get('Clear')}
				</Button>
			</Col>
		</Row>
	</Form>
);
