import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Button, Col, Row } from 'antd';

import { FooterTypes } from '../types';

export const Footer: React.FC<FooterTypes> = ({
	current,
	steps,
	back,
	next,
	isUrlLogoOrganization,
	isUrlLogoCompany,
	handleModalClose
}) => {
	return (
		<Row>
			<Col span={24}>
				<Row justify="center" style={{ marginTop: '20px' }}>
					<Col span="auto">
						{current > 0 && (
							<Button
								style={{ width: '96px', borderRadius: '3px', marginRight: '40px' }}
								onClick={() => back()}
							>
								{I18n.get('Back')}
							</Button>
						)}

						{current < steps.length - 1 && (
							<Button
								style={{ width: '96px', borderRadius: '3px' }}
								type="primary"
								onClick={() => next()}
								disabled={isUrlLogoOrganization}
							>
								{I18n.get('Next')}
							</Button>
						)}

						{current === steps.length - 1 && (
							<Button
								disabled={isUrlLogoCompany}
								type="primary"
								style={{ width: '128px', borderRadius: '3px' }}
								onClick={() => handleModalClose()}
							>
								{I18n.get('Close')}
							</Button>
						)}
					</Col>
				</Row>
			</Col>
		</Row>
	);
};
