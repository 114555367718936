import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Col, Row } from 'antd';

import { LabelStyled, SubTitleStyled, TitleHeaderStyled } from '../../styles';
import { UploadLogo } from '@/components/ui/UploadLogos';
import { useManageOrganization } from '../../../context';
import { FirstPageProps } from '../../../types';

export const FirstPage = (organization: FirstPageProps) => {
	const { fileList, setFileList, setIsUrlLogoOrganization, isUrlLogoOrganization } = useManageOrganization();

	return (
		<Row justify="center" gutter={[24, 0]}>
			<Col span={24}>
				<Row justify="center" align="middle">
					<Col span="auto">
						<TitleHeaderStyled level={1}>{I18n.get('Upload logos')}</TitleHeaderStyled>
					</Col>
				</Row>
			</Col>
			<Col span={24}>
				<Row justify={'center'} gutter={[0, 20]}>
					<Col>
						<SubTitleStyled>{I18n.get('Upload your company logo')}</SubTitleStyled>
					</Col>
				</Row>
				<Row justify={'center'} style={{ marginTop: '21px' }}>
					<Col>
						<UploadLogo
							url={organization.url_logo}
							organizationId={organization.id}
							type={'organization'}
							fileList={fileList}
							setFileList={setFileList}
							setBlockButton={setIsUrlLogoOrganization}
							blockButton={isUrlLogoOrganization}
							isDisable={false}
						/>
					</Col>
				</Row>
				<Row justify={'center'} style={{ marginTop: '10px' }}>
					<Col>
						<LabelStyled>{I18n.get('Upload files')}</LabelStyled>
					</Col>
				</Row>
				<Row justify={'center'}>
					<Col>
						<LabelStyled>{I18n.get('.jpeg or .png up to 5MB')}.</LabelStyled>
					</Col>
				</Row>
			</Col>
		</Row>
	);
};
