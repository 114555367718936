import React, { useState } from 'react';
import { I18n } from '@aws-amplify/core';
import { useSelector } from 'react-redux';
import { Form, Button, Divider, Input, Select } from 'antd';
import { useCreateSector } from '@/hooks/v1/useSectorMutations';

const { useFormInstance } = Form;

export function SectorSelect({ sectors, loading, disabled, ...restProps }) {
	const form = useFormInstance();
	const [sectorName, setSectorName] = useState('');
	const organization = useSelector((state) => state.organization.organization);
	const company = useSelector((state) => state.organization.company);

	const { mutateAsync: createSector } = useCreateSector();

	function handleInputWorkstationReset() {
		form.setFields([
			{
				name: ['workstation_id'],
				value: undefined
			}
		]);
	}

	const onCreateSector = () => {
		const payload = {
			organization_id: organization.id,
			company_id: company.id,
			name: sectorName
		};

		createSector({ body: payload }).then(() => {
			setSectorName('');
			handleInputWorkstationReset();
		});
	};

	const filterOption = (input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

	return (
		<Select
			showSearch
			placeholder={I18n.get('Sector')}
			optionFilterProp="children"
			filterOption={filterOption}
			loading={loading}
			disabled={disabled}
			{...restProps}
			dropdownRender={(menu) => (
				<div>
					{menu}
					<Divider style={{ margin: '4px 0' }} />
					<div
						style={{
							display: 'flex',
							flexWrap: 'nowrap',
							padding: 8
						}}
					>
						<Input
							style={{ flex: 'auto', borderRadius: '7px' }}
							value={sectorName}
							onChange={(e) => setSectorName(e.target.value)}
							placeholder={I18n.get('Create a new sector')}
						/>
						<Button
							onClick={onCreateSector}
							type="link"
							loading={createSector.isLoading}
							disabled={!sectorName || sectorName.length < 3}
						>
							{I18n.get('Add item')}
						</Button>
					</div>
				</div>
			)}
		>
			{sectors?.data?.map((item) => (
				<Select.Option key={item.id} value={item.id}>
					{item.name}
				</Select.Option>
			))}
		</Select>
	);
}
