import React, { ReactNode } from 'react';
import { Row } from 'antd';

import { useManageOrganization } from '../../context';

import { ManageWorkstation } from './ManageWorkstation';
import { ManageCompany } from './ManageCompany';
import { ManageSector } from './ManageSector';

export const Manage = () => {
	const { companyId, sectorId } = useManageOrganization();

	function showManageSector(): ReactNode {
		return !!companyId && <ManageSector />;
	}

	function showManageWorkstation(): ReactNode {
		return !!companyId && !!sectorId && <ManageWorkstation />;
	}

	return (
		<Row style={{ width: '100%' }} gutter={[0, 16]}>
			<ManageCompany />
			{showManageSector()}
			{showManageWorkstation()}
		</Row>
	);
};
