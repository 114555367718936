import React from 'react';
import { Row, Col, Button } from 'antd';
import { I18n } from '@aws-amplify/core';
import { FileAddOutlined } from '@ant-design/icons';
import { Title } from '@/components/Typography';

interface HeaderProps {
	onClick: () => void;
	isDisabled: boolean;
}

export const Header: React.FC<HeaderProps> = ({ onClick, isDisabled }) => (
	<Row justify="center">
		<Col span={12}>
			<Title level={3}>Manage companies</Title>
		</Col>
		<Col span={12}>
			<Button
				ghost
				type="primary"
				onClick={onClick}
				disabled={isDisabled}
				icon={<FileAddOutlined />}
				style={{ float: 'right' }}
			>
				{I18n.get('Create')}
			</Button>
		</Col>
	</Row>
);
