import React from 'react';
import { Collapse } from 'antd';
import { I18n } from '@aws-amplify/core';
import { useForm } from 'antd/lib/form/Form';

import { FormFilter } from './Form';
import { InitialValues } from './types';
import { UrlOrganizationFilter } from '../types';

interface FilterProps {
	onFilter: (filters: UrlOrganizationFilter) => void;
	onClearFilter: () => void;
}

export const Filter: React.FC<FilterProps> = ({ onFilter, onClearFilter }) => {
	const [form] = useForm();

	async function onSubmit(): Promise<void> {
		const { organization, created_at, status } = await form.validateFields();
		const parameters = {
			organizationName: organization,
			createdAt: created_at,
			status: status
		};
		onFilter(parameters);
	}

	function onClear(): void {
		onClearFilter();
		form.resetFields();
	}

	const initialValues: InitialValues = {
		status: 'active'
	};

	return (
		<Collapse>
			<Collapse.Panel header={I18n.get('Filter')} key="1">
				<FormFilter form={form} initialValues={initialValues} onSubmit={onSubmit} onClear={onClear} />
			</Collapse.Panel>
		</Collapse>
	);
};
