import React, { useState } from 'react';
import { Button, Col, Modal, Row, Table, Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table';

import { ExclamationCircleOutlined, StopOutlined } from '@ant-design/icons';
import { I18n } from '@aws-amplify/core';

import { ManageOrganizationModal } from './ManageOrganizationModal';
import { EditOrganization } from './EditOrganization';
import { useManageOrganization } from './context';
import { OrganizationDTO, UrlOrganizationFilter } from './types';
import { Actions } from './Actions';
import moment from 'moment';
import { Spinner } from '@/components/Spinner';
import { Filter } from './Filter';
import { LogosModal } from './LogosModal';

export const OrganizationsTable: React.FC = () => {
	const {
		handleOrganizationId,
		handleDeleteOrganization,
		maxPage,
		page,
		setMaxPage,
		setPage,
		organizationsList,
		organizationsListLoading,
		organizationsListError,
		organization,
		setOrganization,
		setQueryParams,
		isLoading
	} = useManageOrganization();

	const [openManageOrganizationModal, setOpenManageOrganizationModal] = useState(false);
	const [isEditing, setIsEditing] = useState(false);
	const [openLogosModal, setOpenLogosModal] = useState(false);
	const [editingOrganizationId, setEditingOrganizationId] = useState('');
	//const [organization, setOrganization] = useState<OrganizationDTO>({} as OrganizationDTO);

	if (organizationsListLoading || isLoading) {
		return <Spinner />;
	}

	if (organizationsListError) {
		return <div>{I18n.get('Unable to show results')}</div>;
	}

	function handleManageOrganizationModal(organization: OrganizationDTO) {
		setOpenManageOrganizationModal(!openManageOrganizationModal);
		handleOrganization(organization);
	}
	function handleLogosModal(organization: OrganizationDTO) {
		setOpenLogosModal(!openLogosModal);
		handleOrganization(organization);
	}

	function handleOrganization(organization: OrganizationDTO) {
		setOrganization(organization);
		handleOrganizationId(organization.id);
	}

	function handleIsEditing(editing: boolean) {
		setIsEditing(editing);
	}

	function updateOrganization(e: React.MouseEvent<HTMLElement, MouseEvent>, id: string) {
		e.stopPropagation();
		e.preventDefault();

		const editingOrganization = organizationsList.find((organization) => organization.id === id);

		if (editingOrganization) {
			setIsEditing(true);
			setEditingOrganizationId(id);
		}
	}

	function handleClearFilter() {
		setPage(0);
		setMaxPage(10);
		setQueryParams({});
	}
	function deleteOrganization(e: React.MouseEvent<HTMLElement, MouseEvent>, id: string) {
		e.stopPropagation();
		e.preventDefault();

		const content = I18n.get(
			'All companies in this organization will be deleted, along with their respective uploads and reports. Do you wish to proceed?'
		);

		Modal.confirm({
			title: I18n.get('Warning!'),
			icon: <ExclamationCircleOutlined />,
			content: content,
			okType: 'danger',
			okText: I18n.get('Yes'),
			cancelText: I18n.get('Cancel'),
			onOk: async () => {
				await handleDeleteOrganization({ organization_id: id });
			}
		});
	}

	const columns: ColumnsType<OrganizationDTO> = [
		{
			title: I18n.get('Name'),
			dataIndex: 'name',
			key: 'name',
			align: 'center',
			width: '300px',
			sorter: (a, b) => a.name.localeCompare(b.name),
			render: (name: string, organization) => {
				return isEditing && organization.id === editingOrganizationId ? (
					<EditOrganization
						onEditing={handleIsEditing}
						editingOrganizationId={editingOrganizationId}
						organization={organization}
					/>
				) : (
					name
				);
			}
		},
		{
			title: I18n.get('Total Industrial Sites'),
			dataIndex: 'companies',
			key: 'companies',
			align: 'center',
			render: (companies: string[]) => companies?.length || 0,
			sorter: (a, b) => (a.companies ? a.companies.length : 0) - (b.companies ? b.companies.length : 0)
		},
		{
			title: I18n.get('Date of creation'),
			dataIndex: 'created_at',
			key: 'created_at',
			align: 'center',
			render: (created_at: string) => moment(created_at).format('L'),
			sorter: (a, b) => moment(a.created_at).valueOf() - moment(b.created_at).valueOf()
		},
		{
			title: I18n.get('Actions'),
			dataIndex: 'url',
			key: 'url',
			align: 'center',
			render: (_, organization) =>
				organization.is_active ? (
					<Actions
						isEditing={isEditing}
						organization={organization}
						onManageOrganizationModal={handleManageOrganizationModal}
						onLogosUpdate={handleLogosModal}
						onDelete={deleteOrganization}
						onUpdate={updateOrganization}
					/>
				) : (
					<Tooltip title={I18n.get('Please contact support to reactivate it')}>
						<Button type="text">
							{I18n.get('Inactive')}
							<StopOutlined style={{ marginLeft: '8px' }} />
						</Button>
					</Tooltip>
				)
		}
	];

	function handleFilter(filters: UrlOrganizationFilter) {
		setQueryParams(filters);
		setPage(0);
	}

	return (
		<Row gutter={[0, 10]}>
			<Col span={24}>
				<Filter onFilter={handleFilter} onClearFilter={handleClearFilter} />
			</Col>
			<Col span={24}>
				<Table
					rowKey="id"
					dataSource={organizationsList}
					columns={columns}
					pagination={{
						hideOnSinglePage: false,
						showSizeChanger: true,
						total: organizationsList[0]?.total_organizations,
						current: page === 0 ? page + 1 : page,
						defaultPageSize: maxPage,
						pageSizeOptions: ['5', '10', '20', '30', '50', '100', '200'],
						pageSize: maxPage,
						onChange: (page) => {
							setPage(page);
						},
						onShowSizeChange: (_, size) => setMaxPage(size)
					}}
				/>
			</Col>
			{openManageOrganizationModal && (
				<ManageOrganizationModal
					organization={organization}
					isModalOpen={openManageOrganizationModal}
					onModalOpen={setOpenManageOrganizationModal}
				/>
			)}
			{openLogosModal && (
				<LogosModal organization={organization} isModalOpen={openLogosModal} onModalOpen={setOpenLogosModal} />
			)}
		</Row>
	);
};
