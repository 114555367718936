import React, { useState } from 'react';
import { Row, Col } from 'antd';

import { Header } from './Header';
import { useGetPlan } from '@/hooks/useGetPlan';
import { ManageOrganizationProvider } from './context';
import { CreateOrganization } from './CreateOrganization';
import { OrganizationsTable } from './OrganizationsTable';
import { useApplicationContext } from '@/context/Application';

export function Organizations() {
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const { organization, company } = useApplicationContext();
	const { data } = useGetPlan({ organization_id: organization?.id, company_id: company?.id });

	function handleOpenModal(): void {
		setIsOpen((prevState) => !prevState);
	}

	return (
		<ManageOrganizationProvider>
			<Row gutter={[0, 10]}>
				<Col span={24}>
					<Header onClick={handleOpenModal} isDisabled={!!data?.special_plan} />
				</Col>
				<Col span={24}>
					<OrganizationsTable />
				</Col>
				<Col span={24}>
					<CreateOrganization open={isOpen} onClose={handleOpenModal} />
				</Col>
			</Row>
		</ManageOrganizationProvider>
	);
}
