import React from 'react';
import moment from 'moment';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useHistory, useLocation } from 'react-router-dom';

import { RatingTag } from './RatingTag';
import { ErgonomicTools } from '../../types';
import { Text } from '@/components/Typography';
import { ActionsColumn } from '../ActionsColumn';
import { PaginationCustom } from '@/components/Pagination';
import { PaginatedData, type BaseReportsPaginatedResponse, type ScoreResult } from '@/types';

type ReportNameMapper = {
	[key in ErgonomicTools]: string;
};

export interface BaseProps extends BaseReportsPaginatedResponse {}

interface ReportListTableProps<T extends BaseProps> {
	page: number;
	total: number;
	limit: number;
	reportId: string;
	data?: PaginatedData<T>;
	reportName: ErgonomicTools;
}

const reportNameToTitleMapper: ReportNameMapper = {
	reba: 'Risk',
	rula: 'Risk',
	niosh: 'Risk',
	'angle-time': '',
	'kim-pp': 'Risk',
	'kim-mho': 'Risk',
	rosa: 'Final score',
	owas: 'Highest score',
	'strain-index': 'Risk',
	'personalised-report': 'Worst score',
	'preliminary-ergonomic-analysis': 'Worst score',
	'liberty-mutual': 'Percentile (Man | Woman)'
};

export function ReportListTable<T extends BaseProps>({
	data,
	page,
	total,
	limit,
	reportId,
	reportName
}: Readonly<ReportListTableProps<T>>) {
	const history = useHistory();
	const { search } = useLocation();
	const searchParams = new URLSearchParams(search);

	function isConsolidateReport({ text, result }: ScoreResult): boolean {
		return !!text && !!result;
	}

	const columns: ColumnsType<T> = [
		{
			key: 1,
			align: 'center',
			dataIndex: 'report_name',
			title: <Text>Name</Text>,
			sorter: (a: any, b) => a?.report_name.localeCompare(b?.report_name)
		},
		{
			key: 2,
			align: 'center',
			dataIndex: 'organization_name',
			title: <Text>Company</Text>,
			sorter: (a, b) => a.organization_name.localeCompare(b.organization_name)
		},
		{
			key: 3,
			align: 'center',
			dataIndex: 'company_name',
			title: <Text>Industrial Site</Text>,
			sorter: (a, b) => a.company_name.localeCompare(b.company_name)
		},
		{
			key: 4,
			align: 'center',
			dataIndex: 'sector_name',
			title: <Text>Sector</Text>,
			sorter: (a, b) => a.sector_name.localeCompare(b.sector_name)
		},
		{
			key: 5,
			align: 'center',
			dataIndex: 'workstation_name',
			title: <Text>Workstation</Text>,
			sorter: (a, b) => a.workstation_name.localeCompare(b.workstation_name)
		},
		{
			key: 6,
			align: 'center',
			dataIndex: 'created_at',
			title: <Text>Date</Text>,
			render: (created_at: Date) => moment(created_at).format('L'),
			sorter: (a, b) => moment(a.created_at).valueOf() - moment(b.created_at).valueOf()
		},
		...(reportName !== 'angle-time'
			? [
					{
						key: 7,
						align: 'center' as const,
						dataIndex: 'score',
						title: <Text>{reportNameToTitleMapper[reportName] ?? 'Rating'}</Text>,
						sorter: (a: T, b: T) => (a.score?.result ?? 0) - (b.score?.result ?? 0),
						render: (score: ScoreResult) => {
							return score && isConsolidateReport(score) ? (
								<RatingTag score={score} reportName={reportName} />
							) : (
								'-'
							);
						}
					}
			  ]
			: []),
		{
			key: reportName === 'angle-time' ? 7 : 8,
			width: '15%',
			align: 'center',
			dataIndex: 'id',
			title: <Text>Actions</Text>,
			render: (_id, data) => <ActionsColumn reportId={reportId} data={data} reportName={reportName} />
		}
	];

	function handleRowsPerPageChange(rows: number): void {
		searchParams.set('page', `${1}`);
		searchParams.set('rows', `${rows ?? 10}`);
		history.push({ search: searchParams.toString() });
	}

	function handlePageChange(page: number): void {
		searchParams.set('page', `${page ?? 1}`);
		history.push({ search: searchParams.toString() });
	}

	return (
		<>
			<Table rowKey="id" dataSource={data?.rows} columns={columns} pagination={false} />
			<PaginationCustom
				pageSize={limit}
				defaultCurrent={page}
				onChange={handlePageChange}
				total={data?.count ?? total}
				onRowsPerPageChange={handleRowsPerPageChange}
			/>
		</>
	);
}
